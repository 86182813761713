.treeview {
}

.treeview,
.treeview label {
	display: block;
	/*flex-wrap: wrap;*/
	background-color: white;
}

.treeview {
	/*flex: 1;*/
	/*min-width: 12em;*/
}

.treeview [contenteditable] {
	/*flex: 1;*/
	margin-left: 0.5em;
	margin-right: 0.5em;
}

.treeview label span {
	/*display: flex;*/
}

.treeview label label {
	margin-left: 1em;
}

.treeview .icon16 {
	margin-right: 0.25em;
}


.treeview .folder > span {
	display: flex;
	align-items: center;
}

.treeview .folder > span > img {
	padding: 0.25em;
}

.treeview .folder .sub {
	margin-left: 1em;
}

.treeview span:focus,
.treeview span:focus label{
	background-color: #000082;
	color: white;
}

.repo-browser .treeview {
	/*margin-right: 3px;*/
}
