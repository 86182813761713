div.diskette {
	position: relative;
	width: 512px;
	height: 512px;
	margin: 16px;
	transform-origin: top left;
	transform: scale(0.5);
}

div.diskette * {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

div.diskette > .disk {
	pointer-events: initial;
}

div.diskette .disk {
	background-image: url(/sm/disk/disk.png);
	filter: saturate(80%);
}

div.diskette .label {
	background-image: url(/sm/disk/grey-label.png);
	background-image: url(/sm/disk/white-label.png);
	background-image: url(/sm/disk/off-white-label.png);
}

div.diskette .face {
	background-image: url(/sm/disk/face.png);
}

div.diskette .eye-glow {
	background-image: url(/sm/disk/eye-glow.png);
	opacity: 0;
}

div.diskette .glass-flash {
	opacity: 0;
	background-image: url(/sm/disk/glass-flash.png);
	transition: 5s opacity ease-in;
}

div.diskette .slider {
	background-image: url(/sm/disk/slider-closed.png);
	transform: translateX(0px);
	transition: 0.25s transform cubic-bezier(1.000, -0.5, 0.000, 1.5);
}

div.diskette:active .slider {
	transform: translateX(12.5%);
	transition: 0.15s transform cubic-bezier(0.250, 0.25, 0.000, 0.2);
}

div.diskette:active .glass-flash {
	opacity: 1;
}

div.diskette .spark {
	background-color: white;
	width: 8px;
	height: 8px;
	position: absolute;
	--travel: 64px;
	--halftravel: calc(var(--travel) * 0.85);
	--twotravel: calc(var(--travel) / 0.85);
	--left: 128px;
	--top: 320px;
	left: --var(left);
	top: --var(top);
	opacity: 0;
	transform: translate(-50%,-50%);
}

div.diskette:not(:active) .spark {
	animation-name: spark;
	animation-delay: 0.125s;
	animation-duration: 0.333s;
}

div.diskette:not(:active) .spark:nth-child(1) {
	--finishLeft: 128px;
	--finishTop: calc(var(--top) - var(--halftravel));
}

div.diskette:not(:active) .spark:nth-child(2) {
	--finishLeft: calc(var(--left) - var(--travel));
	--finishTop: calc(var(--top) - var(--halftravel));
}

div.diskette:not(:active) .spark:nth-child(3) {
	--finishLeft: calc(var(--left) - var(--twotravel));
	--finishTop: 320px;
}

div.diskette:not(:active) .spark:nth-child(4) {
	--finishLeft: calc(var(--left) - var(--halftravel));
	--finishTop: calc(var(--top) + var(--halftravel));
}

div.diskette .shadow {
	transform: translate(20px, 40px);
	filter: brightness(0) blur(4px);
	opacity: 0.5;
}

/*

div.diskette:not(:active) .spark:nth-child(5) {
	--finishLeft: 160px;
	--finishTop: 0px;
}*/

@keyframes spark {
	from {
		width: 48px;
		height: 48px;
		left: 128px;
		top: 320px;
		opacity: 0;
	}
	30% {
	}
	40% {
		opacity: 1;
		width: 8px;
		height: 8px;
	}
	to {
		opacity: 0;
		width:  0px;
		height: 0px;
		left:   var(--finishLeft);
		top:    var(--finishTop);
	}
}
