.tabs .tab {
	align-self: flex-end;
	padding: 3px 5px;
	border: 2px solid;
	border-bottom-width: 0px;
	border-image: url(/border-tab.png) 2 2 1 2 fill;
	outline-offset: -6px;
}

.tabs {
	margin-top:    1px;
	padding-top:   3px;
	padding-left:  2px;
	margin-bottom: 0px;
	padding-right: 2px;
	z-index: 1;
	position: relative;
}

.tabs .tab:focus,
.tabs .tab:hover {
	margin-top:   -2px;
	color: #000;
	margin-left: -1px;
	margin-right: 1px;
	padding-bottom: 5px;
}

.tabs .tab:focus{
}
