.terminal{
	/*font-family: Unispace;*/
	font-family: 'fixedsys', 'Monaco';
	background-color: black;
	color: white;
	overflow: auto;
	font-size: 12pt;
	font-weight: normal;
}

.terminal input,
.terminal textarea {
	background-color: transparent;
	font-weight: normal;
}

[data-readonly]:not([data-readonly=false]) .terminal input,
[data-readonly]:not([data-readonly=false]) .terminal textarea,
[data-readonly]:not([data-readonly=false]) .terminal .bottom {
	display: none !important;
}

.terminal {
	padding: 1em;
	flex-grow: 1;
}

.console.abs-holder {
	box-sizing: border-box;
	width: auto;
}

.console .abs-fill {
	box-sizing: border-box;
	overflow: auto;
}

@font-face {
	font-family: 'Unispace';
	font-weight:  normal;
	src:          url('/unispace rg.ttf');
}

@font-face {
	font-family: 'Unispace';
	font-weight:  900;
	src:          url('/unispace bd.ttf');
}

@font-face {
	font-family: 'UniFont';
	font-weight:  normal;
	src:          url('/unifont_csur-11.0.02.ttf');
}

@font-face {
	font-family: 'Junicode';
	font-weight:  normal;
	src:          url('/Junicode.woff');
}

@font-face {
	font-family: 'DejaVu';
	font-weight:  normal;
	src:          url('/DejaVuSansMono-webfont.ttf');
}
