.phpEditor .cols.tight > *,
.phpEditor .cols.tight,
.phpEditor label.pane {
	white-space: nowrap;
	z-index: 1;
}

.phpEditor .cols.tight {
	overflow: hidden;
}

.phpEditor label.pane {
	align-items: flex-start;
}

.phpEditor .terminal,
.phpEditor .terminal > *{
	user-select: initial !important;
}

.phpEditor.mode-script .tab.script,
.phpEditor.mode-iffe .tab.iffe,
.phpEditor.mode-term .tab.term {
	text-decoration: underline;
	outline-offset: -6px;
	color: #000;
	margin-left: -1px;
	margin-right: 1px;
}

.phpEditor .ace_editor .ace_scroller {
	background-color: transparent;
}

.phpEditor.mode-script .terminal {
	height: 0px;
	min-height: initial;
}

.phpEditor iframe {
	flex-grow: 1;
	border: none;
}

.phpEditor .ace_editor {
	margin: 0px;
	padding: 1em 0px;
	flex-grow: 1;
	height: auto !important;
}

.phpEditor.loading .scroll {
	background-image: url(/loading.gif);
	background-repeat: no-repeat;
	background-position: center;
}

.mode-term [data-mode-script] {
	display: none;
}

.mode-script [data-mode-term] {
	display: none;
}

.phpEditor .main[data-layout=vertical] {
	flex-direction: row;
}

.phpEditor .main[data-layout=horizontal] {
	flex-direction: column;
}

.phpEditor .main[data-layout=vertical] .terms {
	flex-direction: column;
}

.phpEditor .main[data-layout=horizontal] .terms {
	flex-direction: row;
}

.phpEditor .main[data-layout=quad] {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 50% 50%;
}

.phpEditor .main[data-layout=quad] .terms {
	display: contents;
}

.phpEditor .main[data-layout=quad] [data-vertical-resize] {
	display: none;
}

.phpEditor .main[data-layout=quad] [data-horizontal-resize] {
	display: none;
}
