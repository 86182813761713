[data-role="window-host"] {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
	flex: 1;
	display: flex;
	flex-direction: row;
	align-self: stretch;
	pointer-events: none;
}

.window {
	position: absolute;
	pointer-events: initial;
	height: 520px;
	width: 690px;
	overflow: hidden;
}

.shiny-outline {
	position: absolute;
	width: 50%;
	height: 50%;
	top: 1px;
	left: 1px;
	border: 4px solid white;
	box-sizing: border-box;
	mix-blend-mode: difference;
	z-index: 999999;
	pointer-events: none;
	box-shadow: 0 1em 1em rgba(0,0,0,0.5);
	transition:
		  0.35s 0.1s ease-out top
		, 0.35s 0.1s ease-out left
		, 0.35s 0.1s ease-out width
		, 0.35s 0.1s ease-out height
		, 0.15s 0.0s ease-in  opacity;
}

.shiny-outline.hide {
	opacity: 0;
	transition:
		  0.35s 0.1s ease-out top
		, 0.35s 0.1s ease-out left
		, 0.35s 0.1s ease-out width
		, 0.35s 0.1s ease-out height
		, 0.15s 0.3s ease-in  opacity;
}

.shiny-outline.teleport {
	transition: initial;
}

.window.minimized {
	resize: none;
	width:  128px !important;
	height: auto !important;
	display: inline-flex;
	align-self: flex-end;
	display: none;
}

.window.maximized:not(.minimized) {
	position: absolute;
	top: 0px !important;;
	left: 0px !important;;
	z-index: 100000;
	margin: 0px;
	border-width: 0px;
	border-bottom-width: 2px;
	resize: none;
	width:  100% !important;
	height: 100% !important;
	display: inline-flex;
}

.window.minimized > .title-bar {
	border-width: 1px;
}

.window.maximized:not(.minimized) .title-bar {
	margin: 0px;
}

.window.minimized > *:not(.title-bar) {
	display: none;
}

.window:not(.minimized) {
	align-self: flex-start;
	min-width: 96px;
	min-height: 96px;
}

.window.menu-open {
	overflow: visible;
}

.window.popping {
	animation-name: window-popout;
	animation-timing-function: ease-out;
	animation-duration: 0.45s;
}

@keyframes window-popout {
	from {
		transform: scale(1,1);
	}

	40% {
		transform: scale(.666,.666);

	}

	80% {
		animation-timing-function: ease-in;
		transform: scale(.666,.666);
	}


	to {
		transform: scale(1,1);
	}
}
