.gridview {
}

.gridview th {
	border: 2px solid;
	border-image: url(/border.png) 2 2 2 2;
	background-color: #c3c3c3;
	font-weight: normal;
	position: sticky;
	top: 0;
}

.gridview th:active {
	border-image: url(/border-depressed.png) 2 3 3 2 fill;
}

.gridview th:first-child,
.gridview td:first-child {
	position: sticky;
	left: 0;
	z-index: 1;
}

.gridview th:first-child {
	z-index: 2;
}

.gridview td:first-child {
	background-color: #ddd;
	text-align: center;
}

.gridview td {
	border-top: 1px solid #c3c3c3;
	border-right: 1px solid #c3c3c3;
	white-space: nowrap;
}

.gridview th.wide,
.gridview td.wide {
	width: 100%;
}

.gridview th,
.gridview td {
	padding: 0.25em;
}

.gridview > table {
	border-spacing: 0px 0px;
	min-width: 100%;
	table-layout: fixed;
}

.gridview td,
.gridview > table {
	background-color: #FFF;
}


.gridview td button {
	min-width: unset;
}
