div.container {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
}

div.container iframe {
	width: 100%;
	height: 100%;
	margin: 0 !important;
}

div.sheild {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

div.cards {
	position: absolute;
	z-index: 1;
	top: 2px;
	left: 2px;
	right: 2px;
	bottom: 2px;
	display: flex;
	flex-direction: column;
	pointer-events: none;
	transform: translate3d(0px, 0px, 0px);
}

div.video-card:empty {display: none;}

div.video-card {
	margin-bottom: -10em;
	transition: opacity calc(1ms * var(--timing)) ease-out;
	opacity: 1;
}

div.video-card[data-starting=true],
div.video-card[data-removing=true] {
	opacity: 0;
}

div.video-card {
	--progress: 0;
	position: relative;
	font-weight: bold;
	box-shadow: 0rem 0.5rem 1rem rgba(0,0,0,0.75);
	font-size: 1.25rem;
	display: inline-block;
	padding: 1rem;
	margin: 1rem 1rem 0rem auto;
	max-width: calc(100% - 2rem);
	color: black;
	pointer-events: initial;
	background-color: rgba(255,255,255,0.666);
	backdrop-filter: blur(2px) brightness(0.85);
	border-radius: 2px;
	box-sizing: border-box;
	flex-shrink: 1;
}

div.video-card div.progress-track {
	position: absolute;
	height: 3px;
	bottom: 0px;
	left: 0;
	right: 0;

	background-color: rgba(0,0,0,0.25);
}

div.video-card div.progress-indicator {
	width: 100%;
	height: 100%;

	background-color: rgba(0,0,0,0.5);

	transform-origin: center left;
	transition: transform calc(1ms * var(--timing)) ease-out;
	transform: scaleX(var(--progress));
}

div.video-card.overlay-card {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	/*z-index: 1;*/
	margin: 4rem;

	display: flex;
	justify-content: center;
	align-items: center;

	pointer-events: none;

	background-color: rgba(255,255,255,0.333);

	max-width: unset;
}

div.video-card.tenth .progress-track,
div.video-card.overlay-card .progress-track {
	height: 6px;
}

div.video-card.fat-indicator .progress-track {
	height: 100%;
	opacity: 1;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0);
}

div.video-card.fat-indicator .progress-indicator {
	backdrop-filter: invert(1);
}

div.video-card.special-position {
	position: absolute;
	left: 3rem;
	bottom: 3rem;

	/*z-index: 1;*/
	margin: 0;
}

div.video-card.negative-effect {
	position: absolute;
	left: 8rem;
	bottom: 14rem;

	/*z-index: 1;*/
	margin: 0;

	background-color: rgba(0,0,0,0.35);

	backdrop-filter: invert(1) hue-rotate(calc(180deg * var(--progress)));

	text-shadow: 0 0 0.25rem white;
	color: #222;

	font-size: 2rem;
}

div.video-card.tenth {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;

	margin: 0;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: rgba(0,0,0,0.666);

	backdrop-filter: invert(1) hue-rotate(calc(180deg * var(--progress)));

	pointer-events: none;

	max-width: unset;
}

div.video-card.tenth .content {
	display: flex;
}

div.video-card.tenth span {
	color: white;
	font-size: 2rem;
	text-shadow: 0 0 0.25rem black;
}

div.video-card.tenth span.countdown {
	font-size: 4rem;
	width: 8em;
	text-align: center;
}

div.video-card.tenth .progress-track,
div.video-card.negative-effect .progress-track {
	filter: invert(1);
}
