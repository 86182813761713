.grid-list {
	display: inline-grid;
	--columns: 4;
	grid-template-columns: repeat(calc(-0 + var(--columns)), auto);
	/*grid-template-columns: repeat(calc(var(--columns) - 1),fit-content(0rem)) auto;*/
	/*grid-template-columns: auto auto;*/
	grid-auto-rows: 0fr;
	padding: 0;
	margin: 0rem;
	width: 100%;
	position: relative;
	align-self: start;
	border-right: 1px solid black;
	box-sizing: border-box;
	border-bottom: 1px solid black;
}

.grid-list .header,
.grid-list label {
	display: contents;
}

.grid-list .header span {
	position: sticky;
	z-index: 1;
	border: 2px solid black;
	border-image: url(/border.png) 2 2 2 2;
	background-color: #c3c3c3;
	padding: 4px 2px 4px 2px;
	font-size: 1.1em;
	top: 0;
}

.grid-list .contents .property {
	padding: 4px;
	white-space: pre;
}

.grid-list .contents label .property {
}
.grid-list .contents label:focus-within .property {
	outline: dotted 1px rgba(255,255,255,0.75);
	outline-offset: -2px;
	background-color: #117;
	color: #FFF;
}

.grid-list input {
	min-width: 0;
	border: 0;
	padding: 4px;
	flex-shrink: 0;
	flex-grow: 1;
}

.grid-list span {
	display: flex;
	align-items: center;
	flex-direction: row;
	background-color: white;
	border: 1px solid black;
	border-spacing: 0px 0px;
	margin-bottom: -1px;
	margin-left: -1px;
}
