@font-face {
    font-family: 'fixedsys';
    src: url('fsex302-webfont.woff2') format('woff2'),
         url('fsex302-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html, body {
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	margin: 0px;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	user-select: none;
}

textarea, input, [contenteditable] {
	user-select: initial;
}

textarea[data-wrapping=false] {
	white-space: nowrap;
}

body {
	display: flex;
	flex-direction: column;
}

html, body, input, button, select {
	font-family: fixedsys;
	font-size: 12pt;
}

p, h1 {
	margin-block-start: 0.45em;
	margin-block-end: 0.45em;
}

.viewport {
	position: relative;
	align-self: stretch;
	flex: 1;
	overflow: hidden;
}

.pane {
	position: relative;
	border: 2px solid;
	border-image: url(/border.png) 2 2 2 2;
	background-color: #c0c0c0;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
}

.frame {
	overflow: auto;
	flex-direction: column;
	flex-basis: 100%;
}

.frame .frame {
	/*border: 1px solid #808080;*/
}

.padded {
	padding: 0.5em;
}

.h-padded {
	padding-left: 0.5em;
	padding-right: 0.5em;
}

.v-padded {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

.margin {
	padding: 0.5em;
}

.h-margin {
	padding-left: 0.5em;
	padding-right: 0.5em;
}

.v-margin {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

.frame.margin {
	margin: 0.5em;
}

.liquid {
	display: flex;
	flex: 1;
}

.resize {
	resize: both;
	overflow: hidden;
}

.resize-container > *:not([data-vertical-resize]):not([data-horizontal-resize]) {
	overflow: hidden;
	display: block;
	display: flex;
	flex-basis: 100%;
}

.resize-vert {
	resize: vertical;
	overflow-y: hidden;
}

.resize-hor {
	resize: horizontal;
	overflow-x: hidden;
}

.scroll {
	flex-shrink: 1;
	overflow: auto;
}

.invert-y {
	/*transform: scaleY(-1);*/
}

.white {
	background-color: white;
}

.black {
	background-color: black;
}

.row {
	flex-shrink: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.right {
	justify-content: flex-end !important;
}

.cols {
	display: flex;
	flex-direction: row;
}

.rows {
	display: flex;
	flex-direction: column;
}

.wrap {
	flex-wrap: wrap;
}

.spacer {
	flex: 1;
}

/*label > *:first-child + * {
	margin-left: 0.5em;
}
*/
.inset {
	border: 2px solid;
	border-image: url(/border-inset.png) 2 2 2 2;
	border-color: #c3c3c3;
	margin: 2px !important;
	box-sizing: border-box;
}

img.inset {
	object-fit: contain;
	object-position: top center;
}

.inset:not(.scroll){
	overflow: hidden;
}

.window .editor-toolbar{
	padding: 0px;
}
.window .editor-toolbar::before{
	margin: 0px;
}
.window .editor-toolbar::after{
	margin: 0px;
}

.window .editor-toolbar a,
.window .editor-toolbar i.separator {
	border-image: url(/border.png) 2 2 2 2 fill;
}

.CodeMirror {
	flex: 1;
}

button {
	font-family: fixedsys;
	font-size: 12pt;
	border-style: solid;
	border-image: url(/border.png) 2 2 2 2 fill;
	border-width: 2px 2px 2px 2px;
	border-image-width: 2px 2px 2px 2px;
	outline-offset: -4px;
	text-align: center;
}

 .window button:not(.tight,.square) {
	min-width: 120px;
}

.title-bar button {
	min-width: initial !important;
}

.window button.square {
	display: flex;
	min-width: 24px;
	height: 28px;
	padding: 2px 4px 2px 4px;
}

input.tight,
button.tight {
	height: 22px;
	padding: 0px 6px;
}

.lower {
	align-self: flex-end;
	align-items: stretch;
}

.tight > button.square {
	border-bottom-width: 1px;
	margin-bottom: -1px;
	min-width: 24px;
	height: 24px;
}

.tight > button.square.flat {
	height: auto;
	padding: 1px;
	min-width: 36px;
}

.hide-term .rows > *,
.hide-term .cols > *{
	overflow:hidden;
}

button:active {
	outline-offset: -5px;
	border-image: url(/border-depressed.png) 2 3 3 2 fill;
	border-width: 3px 1px 1px 3px;
	border-image-width: 2px 3px 3px 2px;
}

*:focus {
	/*outline-width: 1px;
	outline-color: black;
	outline-style: dotted;*/
	outline: none;
}

label {
	display: flex;
	align-items: center;
}

input[type="checkbox"],
input[type="radio"] {
	margin: 0.5em;
}

hr {
	margin: 0px;
}

textarea {
	resize: none;
}

progress {
	display: block;
	width: 100%;
	-webkit-appearance: none;
}

progress::-webkit-progress-bar {
	background-color: transparent;
	padding-left: 1px;
	padding-right: 1px;
}
progress::-webkit-progress-value {
	background-color: transparent;
	background-image: url(/progress.png);
	background-repeat: repeat-x;
	background-position: left center
}

ul.task-list {
	background-color: black;
	color: white;
	position: fixed;
	top:0px;
	right:1em;
	text-align: right;
	z-index: 999999999;
	opacity: 0.75;
	pointer-events: none;
	mix-blend-mode: darken;
}

ul.task-list:hover {
}

label.icon-label {
	white-space: nowrap;
	text-align: right;
}

label.icon-frame label {
	display: none;
}

.repo-browser .treeview {
	white-space: nowrap;
	/*display: flex;
	flex: 1;*/
}

.repo-browser .treeview .content {
	/*display: flex;
	flex: 1;*/
}

.repo-browser .treeview > .resize > .folder {
	min-width: 4em;
}

.repo-browser .treeview .resize {
	white-space: nowrap;
	resize: horizontal;
	overflow: auto;
	height: 100%;
}

.repo-browser .main-content {
	padding: 1em;
}

.repo-browser pre.main-content {
	padding: 0.25em 1em;
	display: inline-block;
}

.repo-browser textarea.main-content {
	position: absolute;
	white-space: pre;
	width: 100%;
	height: 100%;
	border: none;
	box-sizing: border-box;
	overflow: visible;
}

.image-control.main-content {
	display: flex;
	flex: 1;
	justify-content:center;
	align-items:center;
	background-color: #c0c0c0;
}

.image-control.main-content img {
	padding: 1em;
}

.plaintext-control {
	margin: 0px;
	flex: 1;
}

.html-control.main-content {
	display: flex;
	flex: 1;
	border: none;
	padding: 0px;
	margin: 1em;
}

.html-control iframe {
	flex: 1;
}

.contents {
	display: contents;
}

.desat {
	filter: saturate(0);
}

.json-view:not(.top-level) {
	display: contents;
}

:not(.expanded) > .json-view-body {
	display: none;
}

.json-view.main-content {
	/*background-color: #aaa;*/
	color: black
}

.json-view .json-view-body {
	margin-left : 2em;
	color: #446;
}

.json-key {
	border: 2px solid;
	border-image: url(/border-inactive.png) 2 2 2 2;
	background-color: #c3c3c3;
	display: inline-block;
	margin-top: 2px;
	margin-bottom: 2px;
	padding: 1px 1px 1px 1px;
	display: inline-block;
}

[data-type=object] > .json-key {
	border-image: url(/border.png) 2 2 2 2;
}

[data-type=object] > .json-key:active {
	border-top-width: 4px !important;
	border-left-width: 4px !important;
	border-right-width: 0px !important;
	border-bottom-width: 0px !important;
	border-image: url(/border-depressed.png) 2 3 3 2;
}

[data-type] > .edit-toggler {
	border: 1px solid #bbb;
	padding: 0.125rem;
	font-size: smaller;
}

[data-type=object] > .edit-toggler {
	display: none;
}

.json-value {
	color: black;
}


[data-type=object] > .json-key > span{
	color: #311;
	cursor: pointer;

}

.CodeMirror-selectedtext {
	background-color: #000082 !important;
	color: white;
}

.wide:not(td):not(th) {
	display: flex;
	flex-grow: 1;
}

.tall {
	justify-self: stretch;
	min-height: 100%;
}

.row.tight > * {
	margin: 0px;
	justify-content: center;
}

.row.centered {
	justify-content: center;
}

.spaced {
	margin: 4px;
}

.over-hide {
	overflow: hidden;
}

.outer {
	position: relative;
	overflow: visible !important;
}

button.dropdown {
	display: flex;
	min-height: 100%;
	align-items:center;
	justify-content: center;
	align-self: stretch !important;
	width: 19px;
}

.left-align {
	text-align: left;
	align-items: flex-start;
}

.flat {
	border: none;
	background-color: transparent;
}

.clear {
	background-color: transparent;
}

.tight > label {
	padding-left: 0.5em;
	padding-right: 0.5em;
}

.tight > img,
.square > img {
	align-self: center;
	image-rendering: pixelated;
}

.tight button {
	align-self: center;
}

button {
	overflow: hidden;
	text-overflow: ellipsis;
}

.tight .tight {
	margin: 0px !important;
}

.ace_line:last-child {
}

/*.output-line {
	position:absolute;
	z-index: 10000;
	background-color: #000033;
	color: white;
	background-blend-mode: color-burn;
}
*/
.ace_markdown-code-unclosed-string {
	color: rgba(200,120,120,1);
	font-weight: bold;
}

.ace_punctuation.ace_xml-decl.ace_xml {
	opacity: 1;
	/*display: none;*/
}

.repos-dropdown {
	position: absolute;
	padding: 0px !important;
	top: calc(100% + 1px);
	/*left: -4px;*/
	right: 0px;
	width: calc(100% + 6px);
	min-width: 440px;
	border-right: none;
}

.repos-dropdown .inset {
	margin: 0px !important;
}

.outer {
	position: relative;
	overflow: visible;
}

.outer .repos-dropdown {
	display: none;
}

.outer .repos-dropdown .scroll{
	height: 300px;
}
.outer:focus .repos-dropdown,
.outer:focus-within .repos-dropdown {
	display: block;
	z-index: 100;
}

[data-section-antileft] {
	display: none;
}

[data-section-antiright] {
	display: none;
}

.hide-left [data-section-antileft] {
	display: initial;
}

.hide-right [data-section-antiright] {
	display: initial;
}

.hide-left [data-section-left] {
	display: none;
}

.hide-right [data-section-right] {
	display: none;
}

.hide-term .term {
	display: none !important;
}

.abs-holder {
	position: relative;
	display: block !important;
	width: 100%;
	height: 100%;
}

.view-control-rendered .abs-fill.plain {
	display: none;
}

.view-control-plain .abs-fill.control {
	display: none;
}

.abs-fill pre.ace_editor,
.abs-fill {
	margin: -1px -1px -1px -1px;
	border: none;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

.miny4em {
	min-height: 4em;
}

.minx4em {
	min-width: 4em;
}

.center-resize-hor {
	resize: horizontal;
	overflow: auto;
}

.hide-right .center-resize-hor {
	resize: none;
	width: auto !important;
	flex: 1;
}

.center-resize-vert {
	resize: vertical;
}

.hide-term .center-resize-vert {
	resize: none;
	width: auto !important;
	flex: 1;
}

[data-vertical-resize] {
	flex-shrink: 0;
	background-color: #c0c0c0;
	cursor: col-resize;
	width: 7px;
	margin-left: -2px;
	margin-right: -2px;
	z-index: 1;
}

[data-horizontal-resize] {
	flex-shrink: 0;
	background-color: #c0c0c0;
	cursor: row-resize;
	height: 7px;
	margin-top: -2px;
	margin-bottom: -2px;
	z-index: 1;
}

[data-control-sector]{
	height: 100%;
}

[data-center-col] {
	flex: 1;
}

[data-terminal-sector] {
	height: 5em;
}

.inset:not(.white) {
	background-color: #c0c0c0;
}

[data-tint] {
	position: relative;
}

[data-tint]::before {
	display: block;
	background-color: red;
	opacity: 0.5;
	mix-blend-mode: color-burn;
	position: absolute;
	z-index: 3;
	left: -2px;
	top: -2px;
	width:  calc(100% + 4px);
	height: calc(100% + 4px);
	content: ' ';
	padding: 0px;
}

[data-center-col] {
	z-index: 1;
}

button.dropdown {
	padding: 0px;
	padding-left: 4px;
	padding-right: 4px;
}

.noshrink {
	flex-shrink: 0;
}

.subWindow .title-bar .minimize {
	display: none;
}

button.flexible {
	min-width: initial !important;
	flex: 1;
}

.selectable {
	user-select: text !important;
	cursor: text;
}

.loading {
	margin: auto;
}

.loading svg {
	width: 80px;
}

pre {
	/*white-space: pre;*/
	font-family: 'fixedsys';
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}
