[data-role=icon-list] {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 0.25em;
	user-select: none;
}

[data-role=icon-list] [tabindex] {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin: 0em;
	word-break: break-word;
}

[data-role=icon-list]:not(.tight) [tabindex] {
	width: 128px;
	min-height: 96px;
	align-items: flex-start;
}

[data-role=icon-list] div:focus {
	outline-style: none;
}

[data-role=icon-list] label:empty {
	display: none;
}

[data-role=icon-list] label {
	padding: 0.25em;
}

[data-role=icon-list] div:focus img {
	outline-color: white;
	filter:
		brightness(50%)
		invert(100%)
		contrast(50%)
		sepia(100%)
		saturate(150%)
		invert(100%);
}

[data-role=icon-list] div img,
[data-role=icon-list] div label {
	align-self: center;
	display: block;
	max-width: 100%;

	margin: 0.25em;
	text-align: center;
}
[data-role=icon-list] div:focus img,
[data-role=icon-list] div:focus label {
	outline-style: dotted;
	outline-width: 1px;
}

[data-role=icon-list] div:focus label {
	outline-color: white;
	outline-offset: -1px;
	background-color: #000082;
	color: #FFF;
}

label > img {
	margin: 0.25em;
}

.icon16 {
	height: 16.1px;
	width: 16.1px;
}

.icon24 {
	width: 24px;
	height: 24px;
}

.icon32 {
	width: 32px;
	height: 32px;
}

.icon64 {
	width: 32px;
	height: 32px;
}

[data-role=icon-list] div.blinking {
	opacity: 0;
}


[data-role=icon-list] div.flashing {
	filter: invert(100%);
}
