/*body::-webkit-scrollbar {
	width: 16px;
	height: 12px;
}

body::-webkit-scrollbar-track {
	background-color: rgba(0, 0, 0, 0.0);
	position: absolute;
	z-index: 0;
}

body::-webkit-scrollbar-thumb {
	padding: 12px !important;
	background-clip: padding-box;
	border: 2px solid rgba(0, 0, 0, 0);
	background-color: rgba(0,0,0, 0.25);
    background-clip: padding-box;
	width: 4px;
	border-radius: 10px;
	position: absolute;
	z-index: 100;
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgba(0,0,0,0.5);
}
*/
