.window.focused .title-bar {
	background-color: #000082;
}

.sub-window .title-bar {
	display: none !important;
}

.title-bar {
	user-select: none;
	background-color: #666;
	color: white;
	justify-content: space-between;
	padding-left: 4px;
	padding-right: 1px;
	padding-top: 1px;
	padding-bottom: 1px;
	margin-top: 1px;
	margin-left: 1px;
	margin-right: 1px;
	margin-bottom: 0px;
	font-size: initial;
	display: flex;
	flex-direction: row;
	font-size: 12pt;
	line-height: 8pt;
	font-family: fixedsys;
}

.title-bar img {
	width: 16.1px;
	height: 16.1px;
	margin-top: 0px;
	margin-bottom: 0px;
	margin-left: 2px;
	margin-right: 4px;
	image-rendering: pixelated;
}

.title-bar span {
	display: flex;
	align-items: center;
}

.title-bar span.title {
	flex: 1;
	overflow: hidden;
	white-space: nowrap;
}

.title-bar button {
	min-width: initial;
	text-align: center;
	margin: 0px;
	width: 15px;
	height: 14px;

	padding: 0px;
	outline-style: none !important;
	border-width: 1px;

	margin-left: 0px;

}

.title-bar button:active {
	border-width: 1px;
}

.title-bar button::after {
	outline-offset: -1px;
	content: '';
	display: block;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	width: 12px;
	height: 10px;

}

.title-bar button.minimize::after {
	background-image: url(/minimize-active.png);
}

.title-bar button.maximize::after {
	background-image: url(/maximize-active.png);
}

.title-bar button.restore,
.minimized .title-bar button.minimize,
.maximized .title-bar button.maximize {
	display: none;
}

.minimized .title-bar button.restore,
.maximized .title-bar button.restore {
	display: initial;
}

.title-bar button.restore::after {
	background-image: url(/restore-active.png);
}

.title-bar button.close {
	/*margin-left: 1px;*/
}

.title-bar button.close::after {
	background-image: url(/close-active.png);
}
