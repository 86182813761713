.widget-viewer-win:not(.minimized) .widget-viewer-win {
	background-color: transparent;
}

.window.widget-viewer-win {
	resize: none !important;
}

.widget-viewer-win.focused .title-bar {
	pointer-events: initial;
	opacity: 1;
}

.widget-viewer-win .title-bar {
	display: flex;
	flex: 1;
	pointer-events: none;
	opacity: 0;
}

.widget-viewer-win .maximize {
	display: none;
}

.widget-viewer-win .title-bar {
	margin: 0px;
}
