.task-bar {
	display: flex;
	flex-direction: row;
	bottom: 0px;
	width: 100%;
	user-select: none;
}

.task-bar .button,
.task-bar button {
	display: flex;
	height: 32px;
	align-items: center;
	padding: 0px;
}

.task-bar button {
	padding: 2px;
}

.task-bar button img {
	padding-right: 8px;
}

.task-bar button {
	padding-left: 8px;
	padding-right: 8px;
}

.task-bar .start {
	position: relative;
}

.task-bar .start .pane {
	display: none;
	position: absolute;
	bottom: 100%;
	left: -3px;
	flex-direction: row;
	z-index: 100;
}

.task-bar .start .pane li .pane {
	left: 100%;
	bottom: 0px;
}

.task-bar .brand-stripe {
	background-color: #000082;
	color: white;
	padding: 0.25em;
	display: flex;
}

.task-bar .brand-stripe img {
	height: 256px;
	margin-top: auto;
}

.task-bar .start:focus-within > .pane {
	display: flex;
}

.task-bar .start .pane ul {
	margin: 0px;
	margin-left: 1px;
	padding: 0px;
	flex: 1;
}

.task-bar .start .pane li {
	padding: 0.5em;
	list-style: none;
	display: flex;
	align-items: center;
	min-width: 160px;
}

.task-bar .start .pane li img {
	margin-right: 0.5em;
}

.task-bar li[tabindex]:focus-within,
.task-bar li[tabindex]:focus,
.task-bar li[tabindex]:hover {
	outline-color: white;
	background-color: #000082;
	color: white;
}

.task-bar ul [tabindex] > .expand {
	margin-right: 0;
	margin-left: auto;
}

.task-bar ul [tabindex]:focus-within > .expand,
.task-bar ul [tabindex]:focus > .expand,
.task-bar ul [tabindex]:hover > .expand {
	filter: invert(100%);
}


.task-bar ul [tabindex] {
	outline-offset: -1px;
}

.task-bar .start li label {
	flex: 1;
}

.task-bar .start li {
	position: relative;
}

.task-bar .start:focus li ul.pane {
	display: none;
	position: absolute;
	left: 100%;
	/*bottom: 0px;*/
	flex-direction: column;
	color: black;
}

.task-bar .start li:focus-within > ul.pane {
	display: block;
}

.task-bar .task-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	flex-grow: 1;
}

.task-bar button{
	min-height: 2em;
}
/*
.task-bar .task-list[data-count] button {
	width: 10%;
	height: 2em;
	box-sizing: border-box;
	white-space: nowrap;
	overflow: hidden;
}*/

.task-bar .task-list button {
	width: 25%;
	min-width: 12em;
	max-width: 16em;
	white-space: nowrap;
}

.task-bar .tray:not(:empty) {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 0.25em;
	padding-right: 0.5em;
}

.tray [data-role=icon-list] div:focus img {
	filter: brightness(110%);
}
